import React from "react";

const CodeConduct = () => {
  return (
    <section className="static_page code_of_conduct">
      <div className="container_width">
        <h1 style={{ textAlign: "start" }}>Code Of Conduct</h1>
        <b>SUMMARY</b>
        <p>
          YOLOH, Inc. (“YOLOH”, “we”, “us”, “our”) is committed to conducting
          business in a manner consistent with our core values. We value our
          employees, customers and business partners, recognizing that all
          parties are essential to protecting our reputation.
        </p>
        <p>
          Our reputation for integrity and our continued success depends on
          conducting business honestly and in accordance with our legal and
          regulatory obligations. Fraud, dishonesty, unethical or criminal
          conduct on the part of any Supplier or anyone doing business with
          YOLOH will not be tolerated.
        </p>

        <p>
          For this reason, YOLOH has established codes of conduct, ethics and
          citizenship. The document herein is specific to expected rules,
          guidelines and behaviors of our Suppliers. The contents are an
          extension of our core values and reflects our dedication to ethical
          business practices and regulatory compliance.
        </p>
        <p>
          For the removal of all doubt, this policy applies to every third-party
          individual or organization (“Supplier”) within the end-to-end supply
          chain of the YOLOH business model, whether providing or receiving
          inputs at a task or transaction level.
        </p>

        <p>
          YOLOH expects our Suppliers will remain familiar and fully committed
          to this Supplier Code of Conduct, now and as updated, and we require
          that all Supplier employees, agents and subcontractors (supplier
          “Representatives”) comply with this Code while conducting business
          with or on behalf of YOLOH.
        </p>
        <p>
          YOLOH reserves the right to modify, revise, discontinue or terminate
          this Supplier Code of Conduct at any time at its sole discretion, with
          or without prior notice.
        </p>
        <b>LEGAL & REGULATORY COMPLIANCE PRACTICES</b>

        <p>
          All Suppliers and their Representatives shall conduct their business
          activities in full compliance with the applicable laws and regulations
          of their respective jurisdictions while conducting business with
          and/or on behalf of YOLOH.
        </p>
        <p>
          In addition, Supplier shall comply with any specific obligation under
          its agreement with YOLOH and all Suppliers shall:
        </p>
        <ol type="a">
          <li>
            Comply with all anti-corruption laws of the countries in which it
            conducts business, including the United States Foreign Corrupt
            Practices Act, and not make any direct or indirect payments or
            promises of payments to foreign government officials for the purpose
            of inducing the individual to misuse his or her position to obtain
            or retain business. YOLOH does not tolerate bribery or corruption in
            any form. Suppliers and those acting on their behalf may not offer,
            promise, authorize, recommend, give or receive, directly or
            indirectly, anything of value to any person or entity if it is
            intended, or could reasonably appear as intended, to influence
            improper action, obtain or retain business, or secure an improper
            advantage.
          </li>
          <br />
          <li>
            Comply with antitrust and unfair competition laws that govern the
            jurisdictions in which they conduct business.
          </li>
          <br />

          <li>
            Act in an environmentally conscious manner and comply with
            applicable environmental laws and regulations.
          </li>
          <br />

          <li>
            Comply with applicable consumer protection laws and regulations.
          </li>
          <br />
        </ol>
        <b>BUSINESS PRACTICES</b>
        <p>
          Suppliers and their Representatives shall conduct their business
          interactions and activities in an ethical manner, with integrity and
          in accordance with their obligations under their specific agreements
          with YOLOH. In addition, Suppliers shall:
        </p>

        <ul>
          <li>
            Accurately and honestly record and report all business information
            and comply with all applicable laws regarding their completion and
            accuracy.
          </li>
          <li>
            Create, retain and dispose of business records in full compliance
            with applicable legal and regulatory requirements.
          </li>
          <li>
            Protect and responsibly use the physical and intellectual assets of
            YOLOH.
          </li>
          <li>
            Use YOLOH provided information technology and systems (including
            email) only for authorized YOLOH business-related purposes.
          </li>
          <li>
            Not use any YOLOH-provided technology and systems to create, access,
            store, print, solicit or send any material that is intimidating,
            harassing, threatening, abusive, sexually explicit or otherwise
            offensive or inappropriate and/or send any false, derogatory or
            malicious communications.
          </li>
          <li>
            Comply with requirements and policies applicable to any YOLOH
            network, systems and buildings.
          </li>
          <li>
            Immediately report to YOLOH any incidents or suspected incidents
            involving data security and disclosure of customer and/or company
            information. Report to support@yoloh.com
          </li>
          <li>
            Comply with all intellectual property rights of YOLOH and any
            third-party whose product it uses to provide service to YOLOH.
          </li>
          <li>
            Speak to the press on YOLOH's behalf only if expressly authorized in
            advance and in writing by a YOLOH executive leader (i.e., Director
            and above).
          </li>
          <li>
            Not give or receive improper gifts. Gifts and entertainment may
            create an inappropriate obligation or expectation on the part of the
            recipient or provider. We expect our Suppliers to refrain from
            providing personal gifts, fees, favors, other compensation or
            business courtesies, including entertainment activities, that are
            intended to influence, or might reasonably appear to influence, a
            business decision. Notwithstanding the above, meals and refreshments
            offered during a meeting are generally acceptable, provided the
            purpose is YOLOH related, the Supplier is in attendance, the cost is
            reasonable and customary, and it is an infrequent occurrence. Our
            goal is to obtain the best commercial terms for YOLOH instead of
            receiving gifts and entertainment. We expect our Suppliers to work
            with us to obtain this outcome.
          </li>
          <li>
            Avoid the appearance of or actual improprieties or conflicts of
            interest: bribes, kickbacks and similar forms of compensation are
            prohibited.
          </li>
        </ul>
        <p>
          YOLOH expects Suppliers to share its commitment to human rights,
          dignity and equal opportunity in the workplace. Suppliers must conduct
          business in full compliance of applicable laws and regulations, and
          shall:
        </p>

        <ul>
          <li>Provide a workplace free of harassment and discrimination.</li>
          <li>Provide a safe and healthy work environment.</li>
          <li>
            Understand and value diversity in the workplace and in supplier
            selection.
          </li>
          <li>
            Not use, possess or distribute illegal drugs or firearms while
            conducting YOLOH business, on or off YOLOH premises.
          </li>
          <li>
            Follow all respective labor laws. Never allow child labor or human
            trafficking.
          </li>
          <li>Honor all respective minimum wage laws.</li>
          <li>
            Prevent physical discipline or abuse, or the threats thereof, or any
            form of sexual or other harassment, abuse or intimidation.
          </li>
          <li>
            Keep employee records, including records of withholding or payment
            of other employment taxes and wages paid in accordance with local
            and national regulations.
          </li>
          <li>Observe any and all collective bargaining agreements.</li>
          <li>
            Not permit the filing of any liens arising out of material
            incorporated therein or work performed in YOLOH owned, leased or
            managed property.
          </li>
          <li>Recognize the rights of employees to freedom of association.</li>
        </ul>

        <b>BUSINESS PARTNERS AND SUBCONTRACTORS</b>

        <p>
          “Business Partners” are Suppliers, vendors and other third-party
          intermediaries that assist YOLOH with obtaining new clients or
          business, retaining existing clients or business or other reasons.
        </p>

        <p>
          Through upfront third-party risk assessment, due diligence, ongoing
          monitoring and Supplier testing, YOLOH will maintain appropriate
          controls related to Business Partners, Suppliers and outsourced
          arrangements. In turn, all Suppliers and Business Partners must meet
          the requirements of YOLOH’s program to include performing due
          diligence, as appropriate, on maintaining internal controls pertaining
          to monitoring the activities and services of their own Business
          Partners and Subcontractors. In addition, Suppliers are expected to
          notify YOLOH of any non-compliance. The Supplier should communicate
          this Supplier Code of Conduct and other related policies to their
          Business Partners and Subcontractors and hold them accountable to
          adhere to the same standards. Ultimately, though, it is the Supplier’s
          responsibility to ensure that their Business Partners and
          Subcontractors comply with the required standard. Suppliers are
          expected to provide any information requested by YOLOH that is needed
          to perform a risk assessment and ongoing monitoring and due diligence,
          including, but not limited to, financial information to assess
          financial viability, disclosure of legal, regulatory and financial
          crime issues, security audit reports where required, and its
          operational risk framework for managing Business Partners and
          Subcontractors.
        </p>
        <b>DATA PRIVACY</b>
        <p>
          Supplier shall comply with all applicable data protection and data
          privacy laws when processing data (personal or otherwise) for or on
          behalf of YOLOH, directly in accordance with YOLOH contractual
          agreements and other applicable instructions. Supplier must not
          process any data for any other purpose for which it has collected,
          received or otherwise made available. Supplier shall, at all times,
          have implemented adequate operational, technical and organizational
          measures to protect data against accidental or unlawful disclosure,
          access, loss, alteration or destruction.
        </p>
        <p>
          In the event that a Supplier has access to, acquires or otherwise
          processes Personal Information, the Supplier will comply with
          applicable laws, regulations and guidance concerning Personal
          Information. “Personal Information” includes, collectively,
          “Personally Identifiable Information,” “Non-public Personal
          Information,” “Personal Data,” and any other similar terms defined by
          applicable data protection or privacy laws.
        </p>

        <p>
          Suppliers must obtain from each of their Representatives authorization
          for YOLOH to image and search a personal device if it is either (a)
          used for YOLOH business, or (b) relevant to an investigation of any
          kind conducted by YOLOH. If personal devices are used for YOLOH
          business, YOLOH may search and image the device in the course of an
          investigation.
        </p>
        <p>
          If a Supplier is requested or required to disclose any of YOLOH’s
          Confidential Information under a subpoena, court order, statute, law,
          rule, regulation, regulatory request or other similar requirement (a
          "Legal Requirement"), the Supplier must, to the extent not precluded
          by law, provide prompt notice of such Legal Requirement to YOLOH prior
          to the disclosure where possible.
        </p>
        <b>BRANDS AND TRADEMARKS</b>
        <p>
          Suppliers may not use the YOLOH brand or trademarks without express
          written permission.
        </p>
        <b>BUSINESS CONTINUITY</b>
        <p>
          YOLOH expects its Suppliers to manage business continuity risk in
          terms of ensuring availability of critical services to YOLOH during a
          disaster event. Therefore, we expect our Suppliers to have plans in
          place for their business to continue with minimal interruption in the
          event of an emergency, crisis situation, natural disaster or
          terrorist/security related event. Suppliers are expected to share
          these plans as requested by YOLOH.
        </p>
        <b>COMPLIANCE WITH CLIENT CODES OF CONDUCT</b>
        <p>
          Where a Supplier is hired by YOLOH on behalf of a client, Supplier
          will comply with all applicable rules, policies, procedures or codes
          of conduct maintained by the client. If Supplier believes that a
          client rule conflicts with anything in this Code and is unsure of what
          to do as a result, Supplier shall work with YOLOH’s management team
          and the client to resolve the matter.
        </p>
        <b>NO CREATION OF THIRD-PARTY RIGHTS</b>
        <p>
          This Code does not confer, nor shall it be deemed to confer, any
          rights on the part of third-parties, including any third-party
          beneficiary rights. For example, no employees of any Supplier shall
          have any rights against YOLOH by virtue of this Code, nor shall such
          employees have any rights to cause YOLOH to enforce any provisions of
          this Code, the decision with respect to any such actions being
          reserved by YOLOH in its sole discretions.
        </p>
        <b>COMPLIANCE WITH THIS CODE</b>
        <p>
          Suppliers must comply with this Code as a condition of doing business
          with YOLOH. Suppliers are expected to self-monitor their compliance to
          ensure their Representatives understand and comply with this Code and
          to inform their YOLOH contact (or a member of the YOLOH management
          team) of any violation of this Code.
        </p>

        <p>
          A Supplier’s failure to comply with the Code may be sufficient cause
          for YOLOH to (a) exercise its right to terminate its business
          relationship with that Supplier or (b) require Supplier to implement a
          corrective action plan to bring the Supplier into compliance with this
          Code.
        </p>
        <b>QUESTIONS AND REPORTING OF POSSIBLE VIOLATIONS</b>
        <p>
          This Code is not intended to address every potential situation that
          relates to our standards of conduct. Anyone encountering situations
          with Suppliers that are not addressed specifically by this Supplier
          Code of Conduct should exercise sound judgment, seek advice when
          appropriate and adhere to the highest ethical standards.
        </p>
        <p>
          Suppliers or their Representatives may direct questions, or
          violations, about this Code to their primary YOLOH contact. You may
          also contact YOLOH at support@yoloh.com
        </p>
        <p>
          Thank you for the partnership we share, and your ongoing commitment to
          operate with the highest levels of ethics, honesty and compliance to
          all laws, regulations and this Code.
        </p>
      </div>
    </section>
  );
};

export default CodeConduct;
