import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const title = {
  dashboard: "Dashboard",
  leads: "Lead Management",
};
const DrawerIndex = ({ children }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const header = useMemo(() => {
    const titles = location?.pathname.split("/");
    if (titles.length > 2) {
      return titles[2] ? titles[2] : "dashboard";
    }
  }, [location?.pathname]);

  return (
    <>
      <div className="layout">
        <section className="main-dashboard">
          <div className="activity-dashboard">
            <h1 className="m-0 p-0 self-center">{title[header]}</h1>
            <div className="d-flex gap-20 search-section">
              <div className="grid-box" onClick={() => setOpen(true)}>
                <div className="grid-icon">
                  <div className="icon-line1"></div>
                  <div className="icon-line2"></div>
                  <div className="icon-line1"></div>
                </div>
              </div>
            </div>
            {children}
          </div>
        </section>
      </div>
    </>
  );
};

export default DrawerIndex;
